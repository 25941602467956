/* eslint-disable camelcase */
// @mui
import { useMemo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';
import conObj from '../../utils/connection-assistant';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '.7rem',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 'auto',
  height: 20,
}));

const CustomScrollbar = styled(Stack)({
  overflowY: 'hidden',
  scrollbarWidth: 'thin',
  scrollbarColor: 'grey #2b2b2b',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#2b2b2b',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f48fb1',
    borderRadius: '10px',
    border: '2px solid #2b2b2b',
  },
});

const Footer = () => {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [pages, setPages] = useState([]);

  const [activeCompanies, setActiveCompanies] = useState([]);

  const isHome = () => {
    if (window.location.pathname === '/' || window.location.pathname === '' || window.location.pathname === '/home') {
      return true;
    }
    return false;
  };

  const {
    config_footer_copyright,
    config_name,
    config_telegram,
    config_wechat,
    config_whatsapp,
    config_facebook,
    config_messenger,
    config_viber,
    config_twitter,
    config_instagram,
    config_youtube,
    config_line,
    config_zalo,
  } = siteSettings || {};

  useEffect(() => {
    /**
     * Get custom pages like TnC etc.
     */
    getPages();

    getActiveCompanies();
  }, []);

  // const copyrightText = useMemo(() => {
  //   if (config_footer_copyright) {
  //     const newText = config_footer_copyright.replace('[year]', getYear(new Date())).replace('[name]', config_name);

  //     console.log(newText);

  //     return newText;
  //   }

  //   return '';
  // }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  // const openSocial = (link) => {
  //   window.open(
  //     link,
  //     '_blank'
  //     // 'noopener noreferrer'
  //   );
  // };

  const getPages = () => {
    conObj
      .post(`/pages.json`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.data?.length > 0) {
            setPages(res.data?.data?.data?.map((item) => ({ pageKey: item?.page_key, title: item?.title })));
          }
        }
      })
      .catch((err) => {
        console.log(`Error: ${err?.message}`);
      });
  };

  const getActiveCompanies = () => {
    conObj.post('/games/active_companies.json').then((res) => {
      if (res.status === 200) {
        setActiveCompanies(res.data?.data?.data);
      }
    });
  };

  // const displayGameProvidersComponent = (items) => {
  //   const midIndex = Math.ceil(items.length / 2);
  //   const firstHalf = items.slice(0, midIndex);
  //   const secondHalf = items.slice(midIndex);

  //   return (
  //     <CustomScrollbar
  //       direction={'column'}
  //       spacing={1}
  //       sx={{
  //         overflowX: 'scroll',
  //         mt: 2,
  //         ml: 0,
  //         width: '100%',
  //         padding: '0 20px 20px 10px',
  //       }}
  //     >
  //       <Stack direction={'row'} spacing={1} sx={{ height: '42px' }}>
  //         {firstHalf.map((item, index) => (
  //           <Image key={index} src={item?.logo_url} alt="Providers" sx={{ minWidth: '96px', height: '42px' }} />
  //         ))}
  //       </Stack>

  //       <Stack direction={'row'} spacing={1} sx={{ height: '42px' }}>
  //         {secondHalf.map((item, index) => (
  //           <Image key={index} src={item?.logo_url} alt="Providers" sx={{ minWidth: '96px', height: '42px' }} />
  //         ))}
  //       </Stack>
  //     </CustomScrollbar>
  //   );
  // };

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  return (
    <Box sx={{ overflow: 'hidden', mt: '20px', pb: '10rem' }}>
      {/* <Typography>Promotion here</Typography> */}

      {isHome() && (
        <>
          <Typography sx={{ fontWeight: 400, fontSize: '1rem', color: '#fff', mb: '.5rem' }}>
            {translate('contact')}
          </Typography>
          <Stack direction="row">
            {config_line && (
              <Image
                src={require('../../assets/footer/socials/line.png')}
                onClick={() => openSocial(config_line)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_telegram && (
              <Image
                src={require('../../assets/footer/socials/telegram.png')}
                onClick={() => openSocial(config_telegram)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_facebook && (
              <Image
                src={require('../../assets/footer/socials/facebook.png')}
                onClick={() => openSocial(config_facebook)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_zalo && (
              <Image
                src={require('../../assets/footer/socials/zalo.png')}
                onClick={() => openSocial(config_zalo)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_instagram && (
              <Image
                src={require('../../assets/footer/socials/instagram.png')}
                onClick={() => openSocial(config_instagram)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_youtube && (
              <Image
                src={require('../../assets/footer/socials/youtube.png')}
                onClick={() => openSocial(config_youtube)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_wechat && (
              <Image
                src={require('../../assets/footer/socials/wechat.png')}
                onClick={() => openSocial(config_wechat)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_whatsapp && (
              <Image
                src={require('../../assets/footer/socials/whatsapp.png')}
                onClick={() => openSocial(config_whatsapp)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_messenger && (
              <Image
                src={require('../../assets/footer/socials/messenger.png')}
                onClick={() => openSocial(config_messenger)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_viber && (
              <Image
                src={require('../../assets/footer/socials/viber.png')}
                onClick={() => openSocial(config_viber)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
            {config_twitter && (
              <Image
                src={require('../../assets/footer/socials/x.png')}
                onClick={() => openSocial(config_twitter)}
                sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
              />
            )}
          </Stack>
        </>
      )}

      <Divider sx={{ height: '1px', my: '1rem' }} />

      <Grid container spacing={1}>
        {pages?.length > 0 &&
          pages.map((item, index) => (
            <Grid item>
              <Typography
                onClick={() => navigate(`/page/${item?.pageKey}`)}
                variant="caption"
                sx={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: '#beabb8',
                  display: 'block',
                  mx: 'auto',
                  p: '.3rem',
                  textAlign: 'center',
                  fontWeight: 400,
                  width: '100%',
                }}
              >
                {item?.title}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Footer;

const _paymentMethod = [
  require('../../assets/powered/payment/nagad.png'),
  require('../../assets/powered/payment/bkash.png'),
  require('../../assets/powered/payment/rocket.png'),
];
