import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Alert, Box, Button, ButtonBase, Grid, Stack, Toolbar, Typography } from '@mui/material';
// import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserMemberBalance } from '../../../redux/slices/lookup';
import { closeLoadingScreen, openLoadingScreen } from '../../../redux/slices/settings';
import { IconButtonAnimate } from '../../../components/animate';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import { ThemeButton } from '../../GlobalStyles';
import useAuth from '../../../hooks/useAuth';
import LanguagePopover from './LanguagePopover';
import conObj from '../../../utils/connection-assistant';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme, showDownloadApp, isDesktop }) => ({
  // ...cssStyles(theme).bgBlur(),
  // boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  backgroundColor: 'transparent',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  top: showDownloadApp && !isDesktop ? 36 : 0,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),

  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
  showDownloadApp,
  setShowDownloadApp,
  isGuestRoute,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { currentLang } = useLocales();

  const { isAuthenticated } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance, gameCategsAndCompanies } = useSelector((x) => x.lookup);

  const navigate = useNavigate();

  const location = useLocation();

  const params = useParams();

  const [referralData, setReferralData] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
      getMemberReferral();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  const getLatestBalance = () => {
    dispatch(openLoadingScreen());
    getUserMemberBalance();

    setTimeout(() => {
      dispatch(closeLoadingScreen());
    }, 1500);
  };

  const getMemberReferral = () => {
    conObj.post('members/referral.json').then((res) => {
      if (res.status === 200) {
        setReferralData(res.data?.data);
      }
    });
  };

  const HeadTitle = useMemo(() => {
    let title = location.pathname.split('/');
    title = translate(title[1]);

    // Below might be slower
    // if (title?.length) title = title[title?.length - 1];
    // else title = '';

    // if (params?.categSlug && !params?.companyId && gameCategsAndCompanies?.length) {
    //   const found = gameCategsAndCompanies.find((x) => x.slug === params?.categSlug);
    //   if (found) title = found?.name;
    //   else title = params?.categSlug;
    //   return title;
    // }

    // if (params?.categSlug && params?.companyId && gameCategsAndCompanies?.length) {
    //   const found = gameCategsAndCompanies
    //     .reduce((acc, item) => acc.concat(item.companies), [])
    //     .find((company) => company.id === params?.companyId);

    //   if (found) title = found?.name;
    //   else title = params?.categSlug;
    //   return title;
    // }

    // if(params?.promotionId) {
    //   title = 'promotion'
    // }

    return title;
    // }, [params, gameCategsAndCompanies]);
  }, [location.pathname]);

  // useEffect(() => {
  //   if (isOpenLoginSignupDialog.open)
  //     setTimeout(() => {
  //       loadCaptchaEnginge(4);
  //     }, 1000);
  // }, [isOpenLoginSignupDialog]);

  const isHome = () => {
    const homeRoutes = ['/', '', '/home'];
    if (homeRoutes.includes(window.location.pathname)) {
      return true;
    }
    return false;
  };

  if (isGuestRoute && !isAuthenticated)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '576px',
          height: 'auto',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
          <LanguagePopover
            PopoverProps={{ arrow: 'top-right' }}
            CustomBtn={
              <Button
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  textTransform: 'uppercase',
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
                  my: 1,
                  mr: 2,
                }}
              >
                <Image src={currentLang?.icon} sx={{ width: '20px', mr: 0.5 }} />
                {currentLang?.value}
              </Button>
            }
          />
        </Box>
        <Logo disabledLink sx={{ width: '70%', height: 'auto' }} />
      </Box>
    );

  return (
    <>
      <Box
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
        showDownloadApp={showDownloadApp}
        isDesktop={isDesktop}
        sx={{ width: '100%', maxWidth: '576px' }}
      >
        <Toolbar
          sx={{
            minHeight: '100% !important',
            pl: { lg: 1 },
            pr: { lg: 2 },
          }}
        >
          <Stack sx={{ width: '100%', py: '1rem' }}>
            <Stack direction={'row'} alignItems="center">
              {isHome() ? (
                <Logo />
              ) : (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  onClick={() => navigate(-1)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Iconify icon={'lucide:arrow-left'} sx={{ width: '25px', height: '25px', color: '#fff' }} />
                  <Typography sx={{ color: '#fff', fontWeight: 500, fontSize: '1rem' }}>{translate('back')}</Typography>
                </Stack>
              )}

              {isHome() ? (
                <Box sx={{ flexGrow: 1 }} />
              ) : (
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 700,
                      fontSize: '18px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {HeadTitle}
                  </Typography>
                </Box>
              )}

              <Stack direction="row" alignItems="center" spacing={1}>
                {!localStorage.getItem('accessToken') ? (
                  <>
                    <Button
                      variant="text"
                      onClick={() => navigate('/auth/login')}
                      sx={{ color: palette.dark.common.white, border: '1px solid #fff' }}
                    >
                      {translate('login')}
                    </Button>
                    <ThemeButton onClick={() => navigate('/auth/register')}>{translate('sign_up')}</ThemeButton>
                  </>
                ) : (
                  <Stack direction={'row'} spacing={0.3} sx={{ alignItems: 'center' }}>
                    <NotificationPopover />

                    {/* <AccountPopover /> */}
                    <IconButtonAnimate
                      onClick={() => navigate('/user/setting')}
                      sx={{
                        p: 0,
                        ml: 0.5,
                        background: '#D17EFF !important',
                      }}
                    >
                      <Iconify icon={'mdi:user-circle'} sx={{ cursor: 'pointer' }} />
                    </IconButtonAnimate>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isAuthenticated ? (
              <>
                <Stack direction={'row'} sx={{ my: '1.5rem' }}>
                  <Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{ fontSize: '14px', color: '#fff' }}>{translate('wallet')}</Typography>
                      <Image
                        src={require('../../../assets/home/refresh.png')}
                        onClick={() => getLatestBalance()}
                        sx={{ width: '17px', height: '17px', cursor: 'pointer' }}
                      />
                    </Stack>
                    <Typography sx={{ color: '#fff', fontWeight: 600, fontSize: '20px', lineHeight: 'normal' }}>{`${
                      memberBalance?.displayBalance ? fNumberDecimal(memberBalance?.displayBalance) : '0'
                    } THB`}</Typography>
                  </Stack>
                  <Box sx={{ flex: 1 }} />
                  <Stack>
                    <Typography sx={{ fontSize: '14px', color: '#fff' }}>{translate('recommendation')}</Typography>
                    <Typography sx={{ color: '#fff', fontWeight: 600, fontSize: '20px', lineHeight: 'normal' }}>
                      {`${fNumberDecimal(
                        parseFloat(referralData?.total_profit.value) -
                          parseFloat(referralData?.total_profit_claim.value)
                      )} THB`}
                    </Typography>
                  </Stack>
                </Stack>
                {isHome() && (
                  <Image
                    src={require('../../../assets/home-banner.png')}
                    sx={{ height: '230px', width: '230px', mx: 'auto' }}
                  />
                )}
              </>
            ) : (
              ''
            )}

            {/* {isOpenLoginSignupDialog.open && (
              <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
            )} */}
          </Stack>
        </Toolbar>
      </Box>
    </>
  );
}
