import { InputBase, Stack, TextField, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import palette from '../theme/palette';

export const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '0.4rem',
  padding: '0.2rem 0.4rem',
  backgroundColor: palette.visitor.input.bg,
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '0.8rem',
  fontWeight: 500,
  // fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 500,
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  // fontSize: '0.8rem',
  // fontWeight: 900,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  border: 'none',
  input: {
    fontSize: '0.8rem',
    fontWeight: 500,
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 500,
      fontSize: '0.8rem',
    },
  },
}));

export const ThemeButton = styled(LoadingButton)(({ theme }) => ({
  background: palette.button.bg,
  color: palette.visitor.login.color,
  // border: '1px solid #D17EFF',
  // borderRadius: '10px',
  // border: palette.button.border,
  // boxShadow: palette.button.shadow,
  fontSize: '0.8rem',
  fontWeight: 400,
  textShadow: '0.5px 1px 1px rgba(0, 0, 0, .4)',
}));

export const TableHeadCustomStyle = {
  backgroundColor: '#8A00AC',
  borderCollapse: 'separate',
  '& th': {
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
    color: 'white',
  },
  '& th:first-of-type': {
    paddingLeft: 2,
    borderLeft: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  '& th:last-of-type': {
    paddingRight: 2,
    borderRight: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
};
