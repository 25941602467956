import React, { useState, forwardRef, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import { getGameCategsAndCompanies, getSiteSettings, getUserMemberBalance } from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories, setActiveTab } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from '../../views/withdraw/WithdrawFormDialog';
import Logo from '../../components/Logo';
import ResetPasswordDialog from './header/forgotpassword/ResetPasswordDialog';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ showDownloadApp, isDesktop, collapseClick, theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: '74px',
  background: 'none',
  // backgroundImage: `url(${require('../../assets/bg.png')})`,
  // backgroundSize: '100%',
  // backgroundPosition: '10% 10%',
  // backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  width: '100%',
  maxWidth: '576px',
  zIndex: 0,
  minHeight: '645px',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // background: 'linear-gradient(180deg, #48456A 15.6%, #201E36 100%)',
    background: '#3D006D',
    opacity: 0.7,
    zIndex: -1,
    borderRadius: '24px 24px 0 0',
  },
  // [theme.breakpoints.only('xs')]: {
  //   paddingTop: showDownloadApp && !isDesktop ? HEADER.MOBILE_HEIGHT + 36 : HEADER.MOBILE_HEIGHT,
  // },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
  [theme.breakpoints.up('sm')]: {
    padding: 24,
  },
  [theme.breakpoints.down('sm')]: {
    padding: 16,
  },
}));

const BottomNavText = styled(Typography)(({ theme }) => ({
  marginTop: 4,
  fontSize: 11,
  fontFamily: 'sans-serif',
  whiteSpace: 'nowrap',
  color: '#fff',
  fontWeight: 400,
  textShadow: '.5px 1px 1px rgba(0, 0, 0, .4)',
}));

const BottomNav = styled(Box)(({ theme }) => ({
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  //   marginLeft: `calc(${NAVBAR.DASHBOARD_WIDTH}px)`,
  // },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const [showDownloadApp, setShowDownloadApp] = useState(true);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const location = useLocation();

  const isGuestRoute = useMemo(() => {
    const guestRoutes = ['/auth/login', '/auth/register', '/promotion'];
    if (guestRoutes.includes(location.pathname)) {
      return true;
    }

    return false;
  }, [location.pathname]);

  useEffect(() => {
    getGameCategsAndCompanies();
    getSiteSettings();
    // getAllGames();
  }, [isAuthenticated]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      // Comment out respond.io code as per Mike
      // if (siteSettings?.config_livechat) {
      //   const respondIo = JSON.parse(siteSettings?.config_livechat);
      //   const script = document.createElement('script');

      //   script.id = respondIo?.id;
      //   script.src = respondIo?.src;
      //   script.async = true;

      //   document.head.appendChild(script);
      // }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  useEffect(() => {
    // if (localStorage.getItem('referralCode') && !isAuthenticated && location.pathname !== '/auth/register') {
    //   console.log(`location.pathname !== '/auth/register'`);
    //   // setTimeout(() => {
    //   navigate('/auth/register');
    //   // }, 1000);
    // }
    // Check if got resetpassword token
    if (sessionStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  // const getAllGames = () => {
  //   conObj.post('games/all.json?limit=1').then((res) => {
  //     if (res.status === 200) {
  //       // res.data?.data?.data
  //       // set appversion to localstorage
  //       // res.data?.data.appversion
  //       const _allGames = res.data?.data?.data;

  //       dispatch(
  //         setGameCategories(
  //           _allGames
  //             .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
  //             .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
  //         )
  //       );
  //     }
  //   });
  // };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          showDownloadApp={showDownloadApp}
          setShowDownloadApp={setShowDownloadApp}
        />

        {/* {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )} */}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: { lg: 1 },
        background: 'linear-gradient(180deg, #470775, #8324C9, #6F23AE, #5B2B93, #3D196F)',
        flexDirection: 'column',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
        showDownloadApp={showDownloadApp}
        setShowDownloadApp={setShowDownloadApp}
        isGuestRoute={isGuestRoute}
      />

      {/* <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

      <MainStyle
        collapseClick={collapseClick}
        showDownloadApp={showDownloadApp}
        isDesktop={isDesktop}
        sx={
          !isGuestRoute || location.pathname.indexOf('promotion') !== -1 ? {} : { '&::before': { background: 'none' } }
        }
      >
        {/* <SubHeaderNav /> */}
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
        <Footer />
        {/* BOTTOM NAVIGATION */}
        <BottomNav
          sx={{
            width: '100%',
            // height: { xs: '70px', md: '90px' },
            // backgroundColor: palette.bottomMenu.bgColor,
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 100,
            display: 'block',
            // p: { xs: '4px 4px 0 4px', md: '20px 20px 0 20px' },
            right: 0,
          }}
        >
          <Grid
            container
            sx={{
              background: 'linear-gradient(180deg, #8646C7, #5B2A94, #3B166E)',
              // backgroundColor: palette.bottomMenu.bgColor,
              // boxShadow: '0px 0px 8px 0px #FFD633',
              // borderRadius: '500px',
              height: '60px',
              borderRadius: '20px 20px 0 0',
              WebkitMask: `url(${require('../../assets/home/bottom_menu.png')}) center no-repeat`,
            }}
          >
            <Grid
              onClick={() => {
                if (!isAuthenticated) navigate('/auth/login');
                else navigate('/home');
              }}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Image src={require('../../assets/home/home.png')} alt="Home" sx={{ width: '18px', height: '18px' }} />
              <BottomNavText>{translate('home')}</BottomNavText>
            </Grid>

            <Grid
              onClick={() => {
                if (!isAuthenticated) navigate('/auth/login');
                else navigate('/deposit');
              }}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Image
                src={
                  isAuthenticated ? require('../../assets/home/deposit.png') : require('../../assets/home/login.png')
                }
                alt="Slot"
                sx={{ width: '30px', height: '20px' }}
              />
              <BottomNavText>{translate(isAuthenticated ? 'deposit' : 'login')}</BottomNavText>
            </Grid>
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '68px',
                  // marginTop: '-20px',
                  width: '68px',
                  borderRadius: '100%',
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ width: '30px', height: '20px' }} />
                <BottomNavText sx={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                  {translate(isAuthenticated ? 'play' : 'register')}
                </BottomNavText>
              </Box>
            </Grid>
            {/* LIVE CASINO */}
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() => {
                if (siteSettings?.config_telegram) {
                  window.open(
                    siteSettings?.config_telegram,
                    '_blank'
                    // 'noopener noreferrer'
                  );
                } else navigate('/promotion');
              }}
            >
              {siteSettings?.config_telegram ? (
                <Iconify icon={'mingcute:telegram-fill'} sx={{ width: '20px', height: '20px' }} />
              ) : (
                <Image
                  src={require('../../assets/home/promotion.png')}
                  alt="Promotion"
                  sx={{ width: '20px', height: '20px' }}
                />
              )}
              <BottomNavText>{siteSettings?.config_telegram ? 'Telegram' : translate('promotion')}</BottomNavText>
            </Grid>

            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() =>
                siteSettings?.config_line
                  ? window.open(
                      siteSettings?.config_line,
                      '_blank'
                      // 'noopener noreferrer'
                    )
                  : {}
              }
            >
              <Image src={require('../../assets/home/line.png')} alt="Contact" sx={{ width: '24px', height: '24px' }} />
              <BottomNavText>{translate('contact_us')}</BottomNavText>
            </Grid>
          </Grid>
          <Box
            // onClick={() => {
            //   if (!isAuthenticated) navigate('/auth/login');
            //   else navigate('user/wallet');
            // }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '68px',
              width: '100%',
              borderRadius: '100%',
              position: 'absolute',
              bottom: '55%',
              left: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, #d2b908, #f8e398, #d2b908)',
                flexDirection: 'column',
                height: '60px',
                width: '60px',
                borderRadius: '100%',
                cursor: 'pointer',
                boxShadow: '0 0 0 0 #FBE339,inset 1px 1px 1px 0 hsla(0,0%,98%,.6),inset 0 0 0 0 hsla(9,6%,79%,.5)',
              }}
              onClick={() => {
                if (!isAuthenticated) navigate('/auth/register');
                else navigate('/providers');
              }}
            >
              <Image
                src={
                  isAuthenticated
                    ? require('../../assets/home/controller.png')
                    : require('../../assets/home/profile.png')
                }
                alt="Play"
                sx={{
                  height: '37px',
                  width: '35px',
                  img: {
                    objectFit: 'contain',
                  },
                }}
              />
            </Box>
          </Box>
        </BottomNav>
      </MainStyle>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer && data.state.visibility === 'minimized') {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}

      {openResetPasswordDialog && (
        <ResetPasswordDialog
          openResetPasswordDialog={openResetPasswordDialog}
          setOpenResetPasswordDialog={setOpenResetPasswordDialog}
        />
      )}
    </Box>
  );
};

export default DashboardLayout;
