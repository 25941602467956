import { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';
import { useDispatch } from '../redux/store';
import { setSelectedImage } from '../redux/slices/imagePreview';

// ----------------------------------------------------------------------

Image.propTypes = {
  disabledEffect: PropTypes.bool,
  effect: PropTypes.string,
  // ratio: PropTypes.oneOf(['4/3', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1']),
  placeholderRatio: PropTypes.oneOf(['4/3', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1']),
  sx: PropTypes.object,
  canPreview: PropTypes.bool,
  src: PropTypes.any,
  imgSx: PropTypes.object,
};

const WrapperLoadedStyle = {
  width: 1,
  height: 1,
  backgroundSize: 'cover !important',
};

const WrapperNotLoadedStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important',
};

export default function Image({
  ratio,
  placeholderRatio = '6/4',
  src,
  canPreview = false,
  disabledEffect = false,
  effect = 'blur',
  sx,
  imgSx,
  isNotFull = false,
  ...other
}) {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const onPreview = () => {
    if (canPreview) dispatch(setSelectedImage(src));
  };

  if (ratio) {
    return (
      <Box
        component="span"
        onClick={onPreview}
        sx={{
          width: 1,
          lineHeight: 0,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          cursor: canPreview ? 'pointer' : 'inherit',
          ...(!isLoaded ? { pt: getRatio(placeholderRatio) } : { pt: getRatio(ratio) }),
          // pt: isNotFull ? `${ratio}` : `calc(100% / ${ratio})`,
          '& .wrapper': isLoaded ? WrapperLoadedStyle : WrapperNotLoadedStyle,
          ...sx,
        }}
      >
        <Box
          component={LazyLoadImage}
          afterLoad={handleLoad}
          wrapperClassName="wrapper"
          effect={disabledEffect ? undefined : effect}
          placeholderSrc="/assets/placeholder.svg"
          sx={{ width: 1, height: 1, objectFit: 'cover', ...imgSx }}
          src={src}
          {...other}
        />
      </Box>
    );
  }

  return (
    <Box
      component="span"
      onClick={onPreview}
      sx={{
        lineHeight: 0,
        display: 'block',
        overflow: 'hidden',
        cursor: canPreview ? 'pointer' : 'inherit',
        ...(!isLoaded ? { width: 1, position: 'relative', pt: getRatio(placeholderRatio) } : {}),
        '& .wrapper': isLoaded ? WrapperLoadedStyle : WrapperNotLoadedStyle,
        ...sx,
      }}
    >
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        afterLoad={handleLoad}
        effect={disabledEffect ? undefined : effect}
        placeholderSrc="/assets/placeholder.svg"
        sx={{ width: 1, height: 1, objectFit: 'cover', ...imgSx }}
        src={src}
        {...other}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

export function getRatio(ratio = '1/1') {
  return {
    '4/3': 'calc(100% / (4 * 3))',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '1/1': '100%',
  }[ratio];
}
